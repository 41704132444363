import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomZoomControl from './CustomZoomControl';
import { Event } from '../../types/Event';
import theme from '../../theme';

type EventMapViewProps = {
    events: Event[];
    showFavorites: boolean;
    favorites: string[];
}

const EventMapView: React.FC<EventMapViewProps> = ({
    events,
    showFavorites,
    favorites,
}) => {
    const navigate = useNavigate();
    const [map, setMap] = useState<L.Map | null>(null);

    const customIcon = L.divIcon({
        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="${theme.palette.secondary.main}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>`,
        className: 'custom-div-icon',
        iconSize: [24, 24],
        iconAnchor: [12, 24],
    });

    const handleMapCreated = React.useCallback((mapInstance: L.Map) => {
        if (mapInstance) {
            setMap(mapInstance);
            mapInstance.setMaxBounds([[-90, -180], [90, 180]]);
            mapInstance.options.maxBoundsViscosity = 1.0;
        }
    }, []);

    const handleMarkerClick = (event: Event) => {
        const searchParams = new URLSearchParams(window.location.search);
        const keywords = searchParams.getAll('keywords');

        const newParams = new URLSearchParams();
        newParams.set('view', 'map');
        keywords.forEach(keyword => {
            newParams.append('keywords', keyword);
        });

        navigate(`/events/${event.id}${newParams.toString() ? `?${newParams.toString()}` : ''}`);
    };

    return (
        <MapContainer
            center={[52.1326, 5.2913]}
            zoom={7}
            style={{
                width: '100%',
                height: '100%'
            }}
            zoomControl={false}
            ref={handleMapCreated}
        >
            <TileLayer
                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                keepBuffer={30}
                updateWhenZooming={false}
                updateWhenIdle={true}
                noWrap={true}
            />
            <CustomZoomControl />
            {events
                .filter(event => showFavorites ? favorites.includes(event.id) : true)
                .map((event) => (
                    event.latitude && event.longitude && (
                        <Marker
                            key={event.id}
                            position={[event.latitude, event.longitude]}
                            icon={customIcon}
                            eventHandlers={{
                                click: () => handleMarkerClick(event),
                            }}
                        />
                    )
                ))}
        </MapContainer>
    );
};

export default React.memo(EventMapView);
