import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import FilterBar from '../components/filter-bar/FilterBar';
import EventList from '../components/event-list/EventList';
import TopBar from '../components/top-bar/TopBar';
import NavigationBar from '../components/navigation-bar/NavigationBar';
import EventService from '../services/eventService';
import { Event } from '../types/Event';
import UserService from '../services/userService';
import { User } from '../types/User';
import SortMenu from '../components/sort-menu/SortMenu';
import EventMap from '../components/event-map/EventMap';

function degreesToRadians(degrees: number) {
  return degrees * Math.PI / 180;
}

function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
}

const getNextOccurrence = (date: string, recurringDay: number): Date => {
  const eventDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  while (eventDate < today || eventDate.getDay() !== recurringDay) {
    eventDate.setDate(eventDate.getDate() + 1);
  }

  return eventDate;
};

const Home: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const eventService = EventService();
  const userService = UserService();
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
  const [highlightedMeetId, setHighlightedMeetId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [showFavorites, setShowFavorites] = useState(false);
  const [favorites, setFavorites] = useState<string[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [userLocation, setUserLocation] = useState<{ lat: number, lng: number } | null>(null);
  const [sortOption, setSortOption] = useState<{ value: string; inverted: boolean }>({ value: 'date', inverted: false });
  const [meetNotFound, setMeetNotFound] = useState<boolean>(false);
  const [isSorting, setIsSorting] = useState(false);
  const [view, setView] = useState<'list' | 'map'>(() => {
    const params = new URLSearchParams(window.location.search);
    const urlView = params.get('view');
    if (urlView === 'map') return 'map';
    return 'list';
  });

  useEffect(() => {
    // Get keywords from URL params on initial load
    const params = new URLSearchParams(window.location.search);
    const urlKeywords = params.getAll('keywords');
    if (urlKeywords.length > 0) {
      setSelectedKeywords(urlKeywords);
      filterEvents('', urlKeywords);
    }
  }, []);

  const handleViewChange = (newView: 'list' | 'map') => {
    setView(newView);
    // Update URL with view parameter while preserving keywords
    const searchParams = new URLSearchParams(window.location.search);
    if (newView === 'map') {
      searchParams.set('view', 'map');
    } else {
      searchParams.delete('view');
    }

    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
    window.history.replaceState(null, '', newUrl);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await userService.getMe();
        setUser(user);
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    };
    fetchUser();
    if (!events.length) {
      const fetchEvents = async () => {
        try {
          setIsLoading(true);
          const fetchedEvents = await eventService.getEvents();
          setEvents(fetchedEvents);

          // Sort events by date before setting state
          const sortedEvents = [...fetchedEvents].sort((a, b) => {
            const dateA = a.recurringDay !== null ? getNextOccurrence(a.date, a.recurringDay!) : new Date(a.date);
            const dateB = b.recurringDay !== null ? getNextOccurrence(b.date, b.recurringDay!) : new Date(b.date);
            return dateA.getTime() - dateB.getTime();
          });

          const params = new URLSearchParams(window.location.search);
          const urlKeywords = params.getAll('keywords');

          const filteredEvents = sortedEvents.filter(event =>
            urlKeywords.every(kw => event.keywords.includes(kw))
          )

          setFilteredEvents(filteredEvents);
        } catch (error) {
          console.error('Failed to fetch events:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchEvents();
    }
  }, []);

  useEffect(() => {
    if (sortOption.value === 'distance') {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.warn("Geolocation is not available in your browser.");
        // setSortOption({ value: 'date', inverted: false });
      }
    }
  }, [sortOption]);

  useEffect(() => filterEvents(), [userLocation])

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const favoriteMeetIds = await userService.getFavoriteMeets();
        setFavorites(favoriteMeetIds);
      } catch (error) {
        console.error('Failed to fetch favorite meets:', error);
        setFavorites([]);
      }
    };

    fetchFavorites();
  }, []);

  const handleFavorite = async (eventId: string) => {
    try {
      if (favorites.includes(eventId)) {
        await userService.removeFavoriteMeet(eventId);
        setFavorites(prevFavorites => prevFavorites.filter(id => id !== eventId));
      } else {
        await userService.addFavoriteMeet(eventId);
        setFavorites(prevFavorites => [...prevFavorites, eventId]);
      }
    } catch (error) {
      console.error('Failed to update favorite status:', error);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    filterEvents(query, selectedKeywords, sortOption);
  };

  const handleFilterChange = (newSelectedKeywords: string[]) => {
    setSelectedKeywords(newSelectedKeywords);
    filterEvents(searchQuery, newSelectedKeywords, sortOption);

    // Update URL params
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('keywords');
    newSelectedKeywords.forEach(keyword => {
      searchParams.append('keywords', keyword);
    });

    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
    window.history.replaceState(null, '', newUrl);
  };

  const handleFavoriteFilter = (showFavorites: boolean) => {
    setShowFavorites(showFavorites);
  };

  const filterEvents = (query: string = searchQuery, keywords: string[] = selectedKeywords, sortOpt = sortOption) => {
    let filtered = events.filter(event =>
      (query === '' || event.title.toLowerCase().includes(query.toLowerCase()) ||
        event.location.toLowerCase().includes(query.toLowerCase())) &&
      (keywords.length === 0 || keywords.every(kw => event.keywords.includes(kw)))
    );

    filtered.sort((a, b) => {
      const dateA = a.recurringDay !== null ? getNextOccurrence(a.date, a.recurringDay!) : new Date(a.date);
      const dateB = b.recurringDay !== null ? getNextOccurrence(b.date, b.recurringDay!) : new Date(b.date);

      switch (sortOpt.value) {
        case 'date':
          return sortOpt.inverted
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
        case 'distance':
          if (userLocation && a.latitude && a.longitude && b.latitude && b.longitude) {
            const distanceA = calculateDistance(userLocation.lat, userLocation.lng, a.latitude, a.longitude);
            const distanceB = calculateDistance(userLocation.lat, userLocation.lng, b.latitude, b.longitude);
            return sortOpt.inverted ? distanceB - distanceA : distanceA - distanceB;
          }
          return 0;
        case 'title':
          return sortOpt.inverted
            ? b.title.localeCompare(a.title)
            : a.title.localeCompare(b.title);
        default:
          return 0;
      }
    });

    setFilteredEvents(filtered);
  };

  const handleScreenClick = () => {
    if (highlightedMeetId) {
      setHighlightedMeetId(null);
    }
  };

  const handleSortChange = async (option: { value: string; inverted: boolean }) => {
    setIsSorting(true);
    setSortOption(option);

    if (option.value === 'distance' && !userLocation) {
      // Get user location if sorting by distance
      try {
        await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setUserLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
              });
              resolve(position);
            },
            (error) => reject(error)
          );
        });
      } catch (error) {
        console.error("Error getting user location:", error);
      }
    }

    filterEvents(searchQuery, selectedKeywords, option);
    setIsSorting(false);
  };

  const handleDeleteEvent = async (id: string) => {
    try {
      await eventService.deleteEvent(id);
      setFilteredEvents(prevEvents => prevEvents.filter(event => event.id !== id));
    } catch (error) {
      console.error('Failed to delete event:', error);
    }
  };

  const handleHighlight = (id: string) => {
    setHighlightedMeetId(id);
  };

  return (
    <Box sx={(theme) => ({
      '@media (min-width: 768px)': {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'center',
        width: '100%'
      }
    })}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          padding: 0,
          '@media (min-width: 768px)': {
            width: '600px',
          },
          filter: meetNotFound ? 'blur(5px)' : 'none',
          pointerEvents: meetNotFound ? 'none' : 'auto'
        })}
      >
        <TopBar onSearch={events.length > 0 ? handleSearch : undefined} onFavoriteFilter={handleFavoriteFilter} view={view} onViewChange={handleViewChange} />
        {events.length > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterBar events={filteredEvents} onFilterChange={handleFilterChange} showFavorites={showFavorites} favorites={favorites} selectedKeywords={selectedKeywords} />
            {view === 'list' && <SortMenu onSortChange={handleSortChange} isLoading={isSorting} />}
          </Box>
        )}
        {view === 'list' ? (
          <EventList
            user={user}
            userLocation={userLocation}
            events={filteredEvents}
            searchQuery={searchQuery}
            loading={isLoading}
            selectedKeywords={selectedKeywords}
            highlightedMeetId={highlightedMeetId}
            showFavorites={showFavorites}
            onScreenClick={handleScreenClick}
            onHighlight={handleHighlight}
            onEdit={(id: string) => navigate(`/edit/${id}`, { state: { from: '/' } })}
            favorites={favorites}
            handleFavorite={handleFavorite}
          />
        ) : (
          <EventMap
            events={filteredEvents}
            user={user}
            isLoading={isLoading}
            userLocation={userLocation}
            showFavorites={showFavorites}
            onHighlight={handleHighlight}
            onEdit={(id: string) => navigate(`/edit/${id}`, { state: { from: '/' } })}
            onDelete={handleDeleteEvent}
            favorites={favorites}
            handleFavorite={handleFavorite}
          />
        )}
      </Box>
      {meetNotFound && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            backgroundColor: 'background.paper',
            padding: 3,
            borderRadius: 2,
            boxShadow: 24,
            textAlign: 'center',
            maxWidth: '90%',
            width: '400px'
          }}
        >
          <Typography variant="h5" gutterBottom>
            Meet niet gevonden
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            De meet waar je naar zoekt bestaat niet of is verwijderd.
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate('/events');
              setMeetNotFound(false);
            }}
          >
            Terug naar overzicht
          </Button>
        </Box>
      )}
      <NavigationBar isAdmin={user?.isAdmin || false} />
    </Box>
  );
};

export default Home;
