import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Radio, ListItemText, CircularProgress } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

type SortOption = {
    label: string;
    value: string;
    inverted: boolean;
};

const sortOptions: SortOption[] = [
    { label: 'Datum', value: 'date', inverted: false },
    { label: 'Afstand', value: 'distance', inverted: false },
];

type SortMenuProps = {
    onSortChange: (option: SortOption) => void;
    isLoading?: boolean;
};

const SortMenu: React.FC<SortMenuProps> = ({ onSortChange, isLoading = false }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOption, setSelectedOption] = useState<SortOption>(sortOptions[0]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionSelect = (option: SortOption) => {
        setSelectedOption(option);
        onSortChange(option);
        handleClose();
    };

    return (
        <div>
            <IconButton 
                sx={{ width: '60px', height: '60px' }} 
                onClick={handleClick}
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={24} /> : <SortIcon />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {sortOptions.map((option) => (
                    <MenuItem
                        key={`${option.value}-${option.inverted}`}
                        onClick={() => handleOptionSelect(option)}
                    >
                        <Radio checked={selectedOption === option} />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default SortMenu;
