import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/fonts/fonts.css';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Home from './pages/Home';
import CreateEvent from './pages/CreateEvent';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';  // Adjust the path as needed
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import Login from './pages/Login'; // Make sure to create this component
import PrivacyStatement from './pages/PrivacyStatement';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordComplete from './pages/ResetPasswordComplete';
import EventDetails from './pages/EventDetails';

const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN || "");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StytchProvider stytch={stytch}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/privacy-statement" element={<PrivacyStatement />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Login register />} />
            <Route path="/create" element={<CreateEvent />} />
            <Route path="/edit/:meetId" element={<CreateEvent />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-password-complete" element={<ResetPasswordComplete />} />
            <Route path="/events/" element={<Home />} />
            <Route path="/events/:meetId" element={<EventDetails />} />
            <Route path="/" element={<Navigate to="/events" replace />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StytchProvider>
  </React.StrictMode>
);
