import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, IconButton, Snackbar, Modal } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { useStytch } from '@stytch/react';
import UserService from '../../services/userService';
import { User } from '../../types/User';
import FullScreenLoader from '../loader/FullScreenLoader';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

type ProfileModalProps = {
    open: boolean;
    onClose: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ open, onClose }) => {
    const stytch = useStytch();
    const [user, setUser] = useState<User | null>(null);
    const [referralCode, setReferralCode] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const userService = UserService();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await userService.getMe();
                setUser(userData);
            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        };
        if (open) {
            fetchUser();
        }
    }, [open]);

    const handleLogout = () => {
        stytch.session.revoke();
        onClose();
    };

    const handleGenerateReferralCode = async () => {
        try {
            const code = await userService.generateReferralCode();
            setReferralCode(code);
        } catch (error) {
            console.error('Failed to generate referral code:', error);
        }
    };

    const handleCopyReferralCode = () => {
        navigator.clipboard.writeText(referralCode);
        setSnackbarOpen(true);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="profile-modal-title"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                pt: 1,
                borderRadius: 2,
                outline: 'none',
            }}>
                <Box sx={{ display: 'flex', width: '100%', mb: 3, justifyContent: 'space-between' }}>
                    <Typography id="profile-modal-title" variant="h6" component="h2" gutterBottom>
                        Profiel
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </Box>
                {!user ? <FullScreenLoader /> : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant="body1">{`Email: ${user.email}`}</Typography>

                        {user.isAdmin && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleGenerateReferralCode}
                                fullWidth
                            >
                                Genereer toegangstoken
                            </Button>
                        )}

                        {referralCode && (
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={referralCode}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <IconButton onClick={handleCopyReferralCode} edge="end">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                        )}

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleLogout}
                            fullWidth
                        >
                            Uitloggen
                        </Button>

                        <Button
                            variant="text"
                            href="mailto:info@carmeeter.com"
                            startIcon={<MailOutlineIcon />}
                            sx={{
                                mt: 2,
                                width: '100%',
                                color: 'text.secondary',
                                borderRadius: 1,
                                padding: '8px 16px',
                                transition: 'all 0.2s ease-in-out',
                            }}
                        >
                            Contact
                        </Button>
                    </Box>
                )}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    autoHideDuration={2000}
                    message="Toegangstoken gekopieerd naar klembord"
                />
            </Box>
        </Modal>
    );
};

export default ProfileModal;