import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardHeader, CardMedia, CardActions, IconButton, Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/StarBorderRounded';
import IosShareIcon from '@mui/icons-material/IosShare';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatInTimeZone } from 'date-fns-tz'
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Event } from '../../types/Event'
import { User } from '../../types/User';
import { useStytchUser } from '@stytch/react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLngExpression } from 'leaflet';
import RoomIcon from '@mui/icons-material/Room';
import L from 'leaflet';
import AuthModal from '../auth-modal/AuthModal';

type EventCardProps = {
    event: Event;
    user: User | null;
    isFavorite: boolean;
    onFavorite: () => void;
    onHighlight: (meetId: string) => void;
    isHighlighted?: boolean;
    onEdit: () => void;
    onDelete: () => void;
    userLocation?: { lat: number, lng: number } | null;
}

const EventCard: React.FC<EventCardProps> = ({
    event,
    user,
    isFavorite,
    onFavorite,
    onHighlight,
    isHighlighted = false,
    onEdit,
    onDelete,
    userLocation,
}) => {
    const { id, title: originalTitle, customTitle, city, date, location, needsAdminAttention, latitude, longitude } = event;
    const displayDate = event.recurringDay !== null
        ? getNextOccurrence(date, event.recurringDay!)
        : date;
    const formattedDate = useMemo(() => {
        const parsedDate = new Date(displayDate);
        return formatInTimeZone(parsedDate, "Europe/London", "d MMMM yyyy - HH:mm");
    }, [displayDate]);
    const title = useMemo(() => customTitle || originalTitle, [customTitle, originalTitle]);
    const subTitle = useMemo(() => customTitle && originalTitle ? originalTitle : null, [customTitle, originalTitle]);
    const navigate = useNavigate();
    const [animateFavorite, setAnimateFavorite] = useState(false);
    const [animateShare, setAnimateShare] = useState(false);
    const [animateOptions, setAnimateOptions] = useState(false);
    const [isFavorited, setIsFavorited] = useState(isFavorite);
    const [isSharing, setIsSharing] = useState(false);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const { user: stytchUser } = useStytchUser();
    const isAdmin = useMemo(() => user?.isAdmin && stytchUser, [user, stytchUser])
    const open = Boolean(anchorEl);
    const [animateMarker, setAnimateMarker] = useState(false);

    useEffect(() => {
        setIsFavorited(isFavorite);
    }, [isFavorite]);

    useEffect(() => {
        setAnimateMarker(true);
        const timer = setTimeout(() => setAnimateMarker(false), 3000);
        return () => clearTimeout(timer);
    }, []);

    const handleFavoriteClick = () => {
        if (stytchUser) {
            setAnimateFavorite(false);
            setIsFavorited(!isFavorited);
            setAnimateFavorite(true);
            onFavorite();
        } else {
            setShowAuthModal(true);
        }
    };

    const handleShare = () => {
        onHighlight(event?.id);
        const originalUrl = window.location.pathname;
        const newUrl = `/events/${event?.id}`;
        window.history.pushState(null, '', newUrl);
        setAnimateShare(false);

        if (navigator.share) {
            setAnimateShare(true);
            setIsSharing(true);
            navigator.share({
                url: `${window.location.origin}/events/${event?.id}`,
            })
                .then(() => {
                    setIsSharing(false);
                    onHighlight('');
                    window.history.pushState(null, '', originalUrl);
                })
                .catch((error) => {
                    console.error('Error sharing:', error);
                    setIsSharing(false);
                    onHighlight('');
                    window.history.pushState(null, '', originalUrl);
                });
        } else {
            onHighlight('');
            window.history.pushState(null, '', originalUrl);
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setAnimateOptions(true);
        setIsOptionsOpen(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setAnimateOptions(false);
        setIsOptionsOpen(false);
    };

    const handleEditClick = () => {
        onEdit();
        handleMenuClose();
    };

    const handleDeleteClick = () => {
        handleMenuClose();
        onDelete();
    };

    const handleOpenLocation = () => {
        if (latitude !== null && longitude !== null) {
            const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
            window.open(mapsUrl, '_blank');
        } else {
            const encodedAddress = encodeURIComponent(location);
            const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
            window.open(mapsUrl, '_blank');
        }
    };

    const handleOpenRoute = () => {
        if (latitude !== null && longitude !== null) {
            const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
            window.open(mapsUrl, '_blank');
        } else {
            const encodedAddress = encodeURIComponent(location);
            const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
            window.open(mapsUrl, '_blank');
        }
    };

    const customIcon = L.divIcon({
        html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="#00B4E6" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>',
        className: 'custom-div-icon',
        iconSize: [24, 24],
        iconAnchor: [12, 24],
    });

    const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
        const earthRadiusKm = 6371;
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadiusKm * c;
    };

    const handleCardClick = (e: React.MouseEvent) => {
        // Prevent navigation if clicking on interactive elements
        if (
            e.target instanceof HTMLElement &&
            (e.target.closest('button') ||
                e.target.closest('.leaflet-container') ||
                e.target.closest('[role="menuitem"]') ||
                e.target.closest('[role="menu"]'))
        ) {
            return;
        }
        navigate(`/events/${event.id}`);
    };

    return (
        <Card
            sx={{
                display: 'flex',
                width: '100%',
                bgcolor: 'background.paper',
                transition: 'box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
                boxShadow: isHighlighted
                    ? '0 0 100px 1px rgba(0, 180, 230, 0.2)'
                    : 'none',
                border: needsAdminAttention
                    ? '2px solid yellow'
                    : 'none',
                animation: needsAdminAttention
                    ? 'pulse 1.5s infinite'
                    : 'none',
                '@keyframes pulse': {
                    '0%': { borderColor: 'yellow' },
                    '50%': { borderColor: 'orange' },
                    '100%': { borderColor: 'yellow' },
                },
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
                WebkitTapHighlightColor: 'transparent',
                '&:active': {
                    backgroundColor: 'background.paper',
                },
            }}
        >
            <Box sx={{ width: '100%' }}>
                {latitude !== null && longitude !== null && (
                    <Box onClick={(e) => {
                        e.stopPropagation();
                        handleOpenLocation();
                    }} sx={{ minWidth: '90px', maxWidth: '300px', height: '100%', padding: 1, flexShrink: 0, border: 'none', }} >
                        <MapContainer
                            center={[latitude, longitude]}
                            zoom={13}
                            zoomControl={false}
                            dragging={false}
                            touchZoom={false}
                            doubleClickZoom={false}
                            scrollWheelZoom={false}
                            attributionControl={false}
                            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                        >
                            <TileLayer
                                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                                detectRetina
                            />
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenLocation();
                                }}
                                sx={{
                                    position: 'absolute',
                                    zIndex: '999',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    animation: animateMarker ? 'bounce 0.5s ease-in-out 3' : 'none',
                                    '@keyframes bounce': {
                                        '0%, 100%': { transform: 'translate(-50%, -50%)' },
                                        '50%': { transform: 'translate(-50%, -70%)' },
                                    },
                                }}
                            >
                                <RoomIcon fontSize='small' />
                            </IconButton>

                        </MapContainer>
                    </Box>
                )}
            </Box>
            <Box sx={{ minWidth: '250px', width: '100%', position: 'relative' }}>
                <CardHeader
                    action={
                        <IconButton
                            aria-label="add to favorites"
                            onClick={handleFavoriteClick}
                            sx={{
                                color: stytchUser ? 'inherit' : 'action.disabled',
                                '&:hover': {
                                    backgroundColor: stytchUser ? 'action.hover' : 'transparent',
                                },
                                position: 'absolute',
                                top: 2,
                                right: 2,
                                height: '40px',
                                width: '40px',
                            }}
                        >
                            {!isFavorited || !stytchUser ? (
                                <StarIcon />
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '16pt',
                                        animation: animateFavorite && isFavorited ? 'twistBounce 1s' : 'none',
                                        '@keyframes twistBounce': {
                                            '0%': { transform: 'perspective(400px) scale(0) rotateY(0)' },
                                            '50%': { transform: 'perspective(400px) scale(1.5) rotateY(360deg)' },
                                            '100%': { transform: 'perspective(400px) scale(1) rotateY(360deg)' },
                                        }
                                    }}
                                >
                                    ⭐️
                                </Typography>
                            )}
                        </IconButton>
                    }
                    title={
                        <Box onClick={handleCardClick}
                            sx={{ fontSize: 0, marginBottom: subTitle ? 0 : 1 }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: title.length > 20 ? '11pt' : title.length > 15 ? '13pt' : '14pt' }} component="span">
                                {title}
                            </Typography>
                            <br />
                            {subTitle && (
                                <Typography sx={{ fontSize: '10pt', fontWeight: 'light' }} component="span">
                                    {subTitle}
                                </Typography>
                            )}
                        </Box>
                    }
                    subheader={
                        <Box>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ marginRight: 1 }}>📅</Typography>
                                <Typography variant="body2" color="text.secondary">{formattedDate}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ marginRight: 1 }}>📍</Typography>
                                <Typography variant="body2" color="text.secondary">{getStreetAddress(location)}</Typography>
                            </Box>
                        </Box>
                    }
                />
                <CardActions disableSpacing>
                    <IconButton
                        aria-label="get directions"
                        onClick={handleOpenRoute}
                        sx={{ height: '40px', width: '40px' }}
                    >
                        <DirectionsIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                    <IconButton
                        aria-label="share"
                        onClick={handleShare}
                        sx={{ height: '40px', width: '40px' }}
                    >
                        {!isSharing ? (
                            <IosShareIcon sx={{ fontSize: "20px" }} />
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: '16pt',
                                    animation: animateShare && isSharing ? 'bounceShare 0.3s' : 'none',
                                    '@keyframes bounceShare': {
                                        '0%': { transform: 'perspective(400px) scale(0)' },
                                        '50%': { transform: 'perspective(400px) scale(1.5)' },
                                        '100%': { transform: 'perspective(400px) scale(1)' },
                                    }
                                }}
                            >
                                🔗
                            </Typography>
                        )}
                    </IconButton>
                    {userLocation && latitude !== null && longitude !== null && (
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                marginLeft: 'auto',
                                marginRight: 1,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {Math.round(calculateDistance(userLocation.lat, userLocation.lng, latitude, longitude))} km
                        </Typography>
                    )}
                    {(isAdmin) && (
                        <Box sx={{ marginLeft: isAdmin ? 'auto' : 0, display: 'flex' }}>
                            <IconButton aria-label="more options" onClick={handleMenuOpen}>
                                {!isOptionsOpen ? (<MoreVertIcon sx={{ fontSize: "20px" }} />
                                ) : <Typography
                                    sx={{
                                        animation: animateOptions && isOptionsOpen ? 'bounceOptions 0.3s' : 'none',
                                        '@keyframes bounceOptions': {
                                            '0%': { transform: 'perspective(400px) scale(0)' },
                                            '50%': { transform: 'perspective(400px) scale(1.5)' },
                                            '100%': { transform: 'perspective(400px) scale(1)' },
                                        }
                                    }}
                                >
                                    🛠
                                </Typography>}
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                            >
                                {isAdmin && (
                                    [
                                        <MenuItem key="edit" onClick={handleEditClick}>Bewerken</MenuItem>,
                                        <MenuItem key="delete" onClick={handleDeleteClick}>Verwijderen</MenuItem>
                                    ]
                                )}
                            </Menu>
                        </Box>
                    )}
                </CardActions>
            </Box>
            <AuthModal open={showAuthModal} onClose={() => setShowAuthModal(false)} message={'Log in om te bewaren'} />
        </Card >
    );
};

const getNextOccurrence = (date: string, recurringDay: number): string => {
    const eventDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const originalHours = eventDate.getHours();
    const originalMinutes = eventDate.getMinutes();

    while (eventDate < today || eventDate.getDay() !== recurringDay) {
        eventDate.setDate(eventDate.getDate() + 1);
    }

    eventDate.setHours(originalHours, originalMinutes);

    return eventDate.toISOString();
};

const getStreetAddress = (fullAddress: string): string => {
    const parts = fullAddress.split(',');
    return parts[0].trim();
};

export default EventCard;
