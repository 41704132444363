import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box } from '@mui/material';

interface DeleteConfirmationProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ open, onClose, onConfirm }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box sx={{ backgroundColor: 'background.default' }}>
                <DialogTitle id="alert-dialog-title">{"Weet je zeker dat je deze meet wilt verwijderen?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="text.secondary">
                        Deze actie kan niet ongedaan worden gemaakt.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button onClick={onClose} color="secondary" variant="outlined" fullWidth>
                        Annuleren
                    </Button>
                    <Button onClick={onConfirm} color="primary" variant="contained" fullWidth>
                        OK
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default DeleteConfirmation;
