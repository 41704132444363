import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';

type TabBarProps = {
    view: 'list' | 'map';
    onViewChange: (view: 'list' | 'map') => void;
}

const TabBar: React.FC<TabBarProps> = ({ view, onViewChange }) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 0.5,
            padding: 1,
        }}>
            <Button
                color={view === 'list' ? 'inherit' : 'primary'}
                variant="text"
                onClick={() => onViewChange('list')}
                startIcon={view === 'list' ? <Typography>📋</Typography> : <ListIcon />}
                sx={{
                    borderBottom: '1px solid',
                    borderColor: view === 'list' ? 'secondary.main' : 'primary.main',
                    borderRadius: 0,
                }}
            >
                Lijst
            </Button>
            <Button
                color={view === 'map' ? 'inherit' : 'primary'}
                variant="text"
                onClick={() => onViewChange('map')}
                startIcon={view === 'map' ? <Typography>🗺️</Typography> : <MapIcon />}
                sx={{
                    borderBottom: '1px solid',
                    borderColor: view === 'map' ? 'secondary.main' : 'primary.main',
                    borderRadius: 0,
                }}
            >
                Kaart
            </Button>
        </Box>
    );
};

export default TabBar;
