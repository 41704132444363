import React, { useState } from 'react';
import { Box } from '@mui/material';
import EventMapView from './EventMapView';
import EventMapModal from './EventMapModal';
import { Event } from '../../types/Event';
import { User } from '../../types/User';
import FullScreenLoader from '../loader/FullScreenLoader';

type EventMapProps = {
    events: Event[];
    user: User | null;
    userLocation?: { lat: number; lng: number } | null;
    onHighlight: (id: string) => void;
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
    showFavorites: boolean;
    favorites: string[];
    handleFavorite: (id: string) => void;
    isLoading: boolean;
}

const EventMap: React.FC<EventMapProps> = (props) => {
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    return (
        <>
            {props.isLoading ? (
                <FullScreenLoader />
            ) : (
                <Box sx={{
                    width: '100%',
                    height: 'calc(100vh - 60px)',
                    position: 'fixed',
                    top: '120px',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflow: 'hidden'
                }}>
                    <EventMapView {...props} />
                    <EventMapModal
                        selectedEvent={selectedEvent}
                        onClose={() => setSelectedEvent(null)}
                        user={props.user}
                        userLocation={props.userLocation}
                        onHighlight={props.onHighlight}
                        onEdit={props.onEdit}
                        onDelete={props.onDelete}
                        favorites={props.favorites}
                        handleFavorite={props.handleFavorite}
                    />
                </Box>
            )}
        </>
    );
};

export default EventMap;
