import React, { useEffect, useState } from 'react';

const PrivacyStatement: React.FC = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/privacy-statement.html')
      .then(response => response.text())
      .then(html => setContent(html))
      .catch(error => console.error('Error loading privacy statement:', error));
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default PrivacyStatement;
