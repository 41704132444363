import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useStytch, useStytchUser } from '@stytch/react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import theme from '../theme';
import ImageCarousel from '../components/ImageCarousel';
import UserService from '../services/userService';

const Login = ({ register }: { register?: boolean }) => {
    const { user } = useStytchUser();
    const isComingSoon = process.env.REACT_APP_COMING_SOON === 'true';
    const stytch = useStytch();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const eventId = new URLSearchParams(location.search).get('event_id');
    const [isRegister, setIsRegister] = useState(register);
    const [needsPasswordCreation, setNeedsPasswordCreation] = useState(false);
    const userService = UserService();
    const [passwordStrength, setPasswordStrength] = useState<'weak' | 'strong' | null>(null);
    const navigate = useNavigate();

    const getRedirectUrl = () => {
        const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || 'http://localhost:3000/';
        return eventId ? `${baseUrl}?event_id=${eventId}` : baseUrl;
    };

    const checkPasswordStrength = (password: string): 'weak' | 'strong' => {
        return password.length >= 14 ? 'strong' : 'weak';
    };

    const handleAuth = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setPasswordStrength(null);

        try {
            if (isRegister) {
                if (password !== confirmPassword) {
                    setError('Wachtwoorden komen niet overeen.');
                    setIsLoading(false);
                    return;
                }
                const response = await stytch.passwords.create({
                    email,
                    password,
                    session_duration_minutes: 60
                });
                if (response.status_code === 200) {
                    window.location.href = getRedirectUrl();
                }
            } else {
                const response = await stytch.passwords.authenticate({
                    email,
                    password,
                    session_duration_minutes: 60
                });
                if (response.status_code === 200) {
                    const user = await userService.getMe();
                    if (user && !user.hasPassword) {
                        setNeedsPasswordCreation(true);
                    } else {
                        window.location.href = getRedirectUrl();
                    }
                }
            }
        } catch (error: any) {
            if (error.error_type === 'user_not_found') {
                setError('Gebruiker niet gevonden. Controleer uw e-mailadres of registreer een nieuw account.');
            } else if (error.error_type === 'invalid_credentials') {
                setError('Ongeldige e-mail/wachtwoord combinatie. Probeer het opnieuw.');
            } else if (error.error_type === 'invalid_email') {
                setError('Ongeldig e-mailadres. Controleer uw invoer en probeer het opnieuw.');
            } else if (error.status_code === 400 && error.error_message === "password appears in a list of breached passwords.") {
                setError('Dit wachtwoord is te vaak gebruikt en mogelijk onveilig. Kies een ander wachtwoord.');
            } else if (error.error_type === 'duplicate_email') {
                setError('Er bestaat al een account met dit e-mailadres. Probeer in te loggen of gebruik een ander e-mailadres.');
            } else {
                setError('Er ging iets mis. Probeer het opnieuw.');
            }
            console.error('Login error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordCreation = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        if (password !== confirmPassword) {
            setError('Wachtwoorden komen niet overeen.');
            setIsLoading(false);
            return;
        }

        try {
            const response = await stytch.passwords.create({
                email,
                password,
                session_duration_minutes: 60
            });
            if (response.status_code === 200) {
                window.location.href = getRedirectUrl();
            }
        } catch (error) {
            setError('Er ging iets mis bij het aanmaken van het wachtwoord. Probeer het opnieuw.');
            console.error('Password creation error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (user) {
        return <Navigate to={eventId ? `/events/${eventId}` : "/events"} replace />;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'relative',
            overflow: 'hidden',
            paddingTop: "300px",
        }}>
            <Box sx={{
                position: 'absolute',
                top: '25px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
                <ImageCarousel />
            </Box>
            <Box sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                top: '200px',
                '@media (max-width: 768px)': {
                    top: '150px',
                },
                left: 0,
                width: '100%',
                height: 'auto',
                zIndex: 1000,
            }}>
                {isComingSoon ? <>
                    <Typography sx={{ position: 'fixed', top: '50%' }} fontWeight={700} variant={'h6'}>
                        Coming soon...
                    </Typography>
                </> :
                    <Box
                        component="form"
                        onSubmit={handleAuth}
                        sx={{
                            width: "300px",
                            backgroundColor: theme.palette.background.paper,
                            padding: 3,
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {needsPasswordCreation ? (
                            <>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Maak een wachtwoord aan
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="Wachtwoord"
                                    variant="outlined"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    sx={{ mb: 2 }}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Bevestig wachtwoord"
                                    variant="outlined"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    sx={{ mb: 2 }}
                                    required
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={isLoading}
                                    sx={{ mb: 2 }}
                                    onClick={handlePasswordCreation}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : 'Wachtwoord aanmaken'}
                                </Button>
                                {error && (
                                    <Typography color="error" sx={{ marginBottom: 2, textAlign: 'center' }}>
                                        {error}
                                    </Typography>
                                )}
                            </>
                        ) : (
                            <>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    sx={{ mb: 2 }}
                                    required
                                    autoComplete="email"
                                    inputProps={{
                                        autoCapitalize: "none",
                                        autoCorrect: "off"
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Wachtwoord"
                                    variant="outlined"
                                    type="password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        if (isRegister) {
                                            setPasswordStrength(checkPasswordStrength(e.target.value));
                                        }
                                    }}
                                    sx={{ mb: 2 }}
                                    required
                                />
                                {isRegister && (
                                    <>
                                        <TextField
                                            fullWidth
                                            label="Bevestig wachtwoord"
                                            variant="outlined"
                                            type="password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            sx={{ mb: 0 }}
                                            required
                                        />
                                        {(error || (isRegister && passwordStrength)) && (
                                            <Typography
                                                sx={{
                                                    marginBottom: 2,
                                                    textAlign: 'center',
                                                    color: isRegister && passwordStrength === 'strong' ? 'green' : 'error.main'
                                                }}
                                            >
                                                {error || (isRegister && passwordStrength === 'weak' ? 'Wachtwoord moet minimaal 14 tekens lang zijn' : 'Wachtwoord is sterk genoeg')}
                                            </Typography>
                                        )}
                                    </>
                                )}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    disabled={isLoading}
                                    sx={{ mb: 0 }}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : (isRegister ? 'Account aanmaken' : 'Login')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    fullWidth
                                    onClick={() => navigate('/')}
                                    sx={{ mt: 1 }}
                                >
                                    Sluiten
                                </Button>
                                <Button
                                    onClick={() => setIsRegister(!isRegister)}
                                    sx={{ mb: 2, px: 3 }}
                                >
                                    <Typography>
                                        {isRegister ?
                                            `Heb je al een account?
                                    Login` :
                                            `Heb je nog geen account? 
                                    Registreer`}
                                    </Typography>
                                </Button>
                                <Button
                                    variant="text"
                                    onClick={() => navigate('/reset-password')}
                                    sx={{ mt: 1 }}
                                >
                                    Wachtwoord vergeten?
                                </Button>
                            </>
                        )}
                    </Box>
                }
            </Box>
        </Box>
    );
};

export default Login;