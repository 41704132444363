import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import loginImage1 from '../assets/carmeeter-logo-1.png';
import loginImage2 from '../assets/carmeeter-logo-2.png';
import loginImage3 from '../assets/carmeeter-logo-3.png';

const ImageCarousel = () => {
    const images = [loginImage1, loginImage2, loginImage3];
    const [currentImageIndex, setCurrentImageIndex] = useState(Math.floor(Math.random() * 3));
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsTransitioning(true);
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
                setIsTransitioning(false);
            }, 1000);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Box 
            sx={{
                position: 'absolute',
                top: 0,
                left: '50%',
                width: '300px',
                transform: 'translateX(-50%)',
                '@media (max-width: 768px)': {
                    width: '225px',
                }
            }}
        >
            <Box
                component="img"
                src={images[currentImageIndex]}
                alt="Login"
                sx={{
                    width: '100%',
                    height: 'auto',
                    opacity: isTransitioning ? 0 : 1,
                    transform: isTransitioning ? 'translateY(-100px)' : 'translateY(0)',
                    transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
                    animation: 'initialSlideUp 1s ease-out',
                    '@keyframes initialSlideUp': {
                        '0%': {
                            opacity: 0,
                            transform: 'translateY(100%)',
                        },
                        '100%': {
                            opacity: 1,
                            transform: 'translateY(0)',
                        },
                    },
                }}
            />
        </Box>
    );
};

export default ImageCarousel;
